var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "my-enlis",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.hideSearch.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "ybox-mod enbuss-mod" }, [
        _c(
          "div",
          { staticClass: "ybox-title" },
          [
            _c("card-header", {
              staticClass: "ybox-title",
              attrs: { title: _vm.headerTitle, edit: _vm.$attrs.edit },
              on: { deleteData: _vm.deleteData },
              scopedSlots: _vm._u([
                {
                  key: "left",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "pull-left" },
                        [
                          _vm.flag
                            ? _c("en-icon", {
                                staticStyle: {
                                  "vertical-align": "middle",
                                  cursor: "pointer",
                                  "margin-left": "0px",
                                },
                                attrs: { name: "fanhui", size: "small" },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.callback.apply(null, arguments)
                                  },
                                },
                              })
                            : _vm._e(),
                          _c("span", [_vm._v("已办事项")]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "right",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "pull-right" },
                        [
                          _vm.type === "itself" && !_vm.$attrs.edit
                            ? _c("viewSearch", {
                                attrs: { show: _vm.show },
                                on: { toData: _vm.getData },
                              })
                            : _vm._e(),
                          _vm.type === "itself" && !_vm.$attrs.edit
                            ? _c("span", { staticClass: "shu" })
                            : _vm._e(),
                          _vm.type === "itself" &&
                          _vm.sortType === "001" &&
                          _vm.sortLoad &&
                          !_vm.$attrs.edit
                            ? _c(
                                "en-image-setting",
                                {
                                  staticClass: "piliangchuli-weidu",
                                  attrs: {
                                    name: "jiangxu",
                                    size: "small",
                                    fill: "rgb(169, 181, 198)",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.sortClick(
                                        "000",
                                        "taskDoneSortType"
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 降序 ")]
                              )
                            : _vm._e(),
                          _vm.type === "itself" &&
                          _vm.sortType === "000" &&
                          _vm.sortLoad &&
                          !_vm.$attrs.edit
                            ? _c(
                                "en-image-setting",
                                {
                                  staticClass: "piliangchuli-weidu",
                                  attrs: {
                                    name: "shengxu1",
                                    size: "small",
                                    fill: "rgb(169, 181, 198)",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.sortClick(
                                        "001",
                                        "taskDoneSortType"
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" 升序 ")]
                              )
                            : _vm._e(),
                          _vm.type === "index"
                            ? _c("en-icon", {
                                staticStyle: {
                                  color: "#A9B5C6",
                                  cursor: "pointer",
                                  "margin-left": "10px",
                                },
                                attrs: {
                                  name: "zuocecaidanlan-shouqi",
                                  size: "20",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.goDetails("/handle")
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "en-loading",
                rawName: "v-en-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "ybox-content",
          },
          [
            !_vm.noData
              ? _c(
                  "div",
                  {
                    staticClass: "enlis enlis-buss",
                    attrs: { id: "handle-enlis" },
                  },
                  _vm._l(_vm.noticeList, function (item, k) {
                    return _c(
                      "div",
                      {
                        key: k,
                        staticClass: "mod",
                        on: {
                          click: function ($event) {
                            return _vm.modClick(item, k)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "col col-icon" }, [
                          _c(
                            "p",
                            [
                              _c("en-user-logo", {
                                staticStyle: { width: "40px", height: "40px" },
                                attrs: {
                                  "user-name": item.createName,
                                  "image-url": item.userLogo,
                                  size: "40",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "pull-left" }, [
                          _c("div", { staticClass: "col col-num" }, [
                            _c(
                              "p",
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      (item.barCode || "") +
                                        " " +
                                        (item.createName || "")
                                    ) +
                                    " "
                                ),
                                item.assignee !== _vm.userId
                                  ? _c("en-icon", {
                                      staticStyle: { color: "#3e90fe" },
                                      attrs: {
                                        name: "dailishenpiren",
                                        size: "16",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "col col-tit" }, [
                            _c("p", [_vm._v(_vm._s(item["message"] || ""))]),
                          ]),
                        ]),
                        _c("div", { staticClass: "pull-right" }, [
                          _c("div", { staticClass: "col col-time" }, [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.getDateFormat(item["modifyTime"]))
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col col-operate" },
                            [
                              _c(
                                "el-tag",
                                {
                                  staticStyle: {
                                    height: "24px",
                                    "line-height": "24px",
                                    border: "none",
                                    "border-radius": "2px",
                                  },
                                  style:
                                    "color:" +
                                    _vm.color[item.billStatus][0] +
                                    ";" +
                                    "background:" +
                                    _vm.color[item.billStatus][1] +
                                    ";",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.billStatusName ||
                                          _vm.billStatus[item.billStatus]
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.noData
              ? _c("en-result", { attrs: { type: "NoData" } })
              : _vm._e(),
            _vm.myNotice.hasNext
              ? _c("viewAddMore", {
                  attrs: {
                    total: _vm.myNotice.total,
                    pageNo: _vm.pageNo,
                    pageSize: _vm.pageSize,
                  },
                  on: {
                    addMore: function ($event) {
                      return _vm.addMore("/handle")
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("viewBacktop", {
        staticClass: "back-top",
        attrs: { elementId: "handle-enlis" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }